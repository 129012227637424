import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './router'
const packageJSON = require('../package.json');
Vue.prototype.$packageJSON = packageJSON;
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import VeeValidate from 'vee-validate';

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

Vue.config.productionTip = false

// store...
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// functions..
import {clearLSAfterExpiryTime} from './clearLSAfterExpiryTime'
clearLSAfterExpiryTime()

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// router...
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes
})

// i18n...
// import $t from '@/lib/i18n';

import i18n from './i18n'

// Vue.prototype.$t = $t;

// registered global components...
Vue.component('typeahead', VueTypeaheadBootstrap);
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors'
})

new Vue({
  router,
  pinia,
  i18n,
  render: h => h(App)
}).$mount('#app')
